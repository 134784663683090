import {classNames} from "primereact/utils";
import {ProgressBar} from "primereact/progressbar";
import React from "react";
import {useAppSelector} from "../../../../redux/hooks";
import {DEV, STAGE} from "../../../../redux/config";

const ConsultationSipDetails = (props: any) => {
    const monitoringSipData = useAppSelector(state => state.MonitoringListReducer?.consultationSipData);
    const detailsLoading = useAppSelector(state => state.MonitoringListReducer.sipDetailsLoading);
    const ownerTypeDictionary = useAppSelector(state => state.MonitoringListReducer.ownerTypeDictionary);
    const formatDateTime = (dateTimeStr: string): string => {
        const date = new Date(dateTimeStr);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
    };

    let foundOwnerType = ownerTypeDictionary?.filter((status: any) => status?.dictionaryId === monitoringSipData?.ownerType);

    const fields = [
        {label: "Оператор", value: monitoringSipData.fullName || '- - -'},
        ...((monitoringSipData?.campaignName || monitoringSipData?.campaignFullName) || (monitoringSipData?.queueName || monitoringSipData?.queueFullName) ? [{
            label: monitoringSipData?.campaignId ? 'ID / Назва Кампанii' : 'ID / Назва Черги',
            value: monitoringSipData?.campaignFullName
                ? monitoringSipData.campaignFullName
                : monitoringSipData?.campaignId
                    ? `${monitoringSipData.campaignId} ${monitoringSipData.campaignName}`
                    : monitoringSipData?.queueFullName
                        ? monitoringSipData.queueFullName
                        : `${monitoringSipData.queueId} ${monitoringSipData.queueName}`        }] : []),
        {label: "Початок", value: monitoringSipData.start ? formatDateTime(monitoringSipData.start) : '- - -'},
        {label: "Опис", value: monitoringSipData.description || '- - -'},
    ];

    return (
        <div className={`relative p-2 sipDetails mt-2 ${DEV ? 'sipDetails-dev' : STAGE ? 'sipDetails-stage' : ''}`} style={{backgroundColor: '#2e323f', borderRadius: '6px'}}>
            {detailsLoading && <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>}
            <div className={classNames('grid', {'opacity-50': detailsLoading})}
                 style={{paddingTop: "0.3rem", padding: '0.3rem 10px 5px'}}>
                {(monitoringSipData.contactName || monitoringSipData.pathSegment) ?
                    <div className="grid col-12 md:col-12 mb-0 px-0 align-items-center">
                        <div className="field col-10 md:col-10 flex align-items-center m-0">
                            {monitoringSipData.pathSegment ?
                                <a target="_blank" className="mb-2" href={`${monitoringSipData.pathSegment}?sessionId=${monitoringSipData?.sessionId}`}>{monitoringSipData.contactName}</a> :
                                <p className="m-0 mb-2">{monitoringSipData.contactName}</p>}
                        </div>
                        <div className="field col-2 md:col-2 flex align-items-center m-0">
                            <button className="close-button block cursor-pointer ml-auto"
                                    onClick={() => {
                                        props.setVisible(null);
                                    }
                                    }><span className="pi pi-times"></span>
                            </button>
                        </div>
                    </div> :
                    <div className="field col-12 md:col-12 flex flex-wrap align-items-center m-0">
                        <button className="close-button block cursor-pointer ml-auto"
                                onClick={() => {
                                    props.setVisible(null);
                                }
                                }><span className="pi pi-times"></span>
                        </button>
                    </div>}
                <div className="field col-12 md:col-12 p-0"
                     style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                <div className="field col-6 md:col-7 flex align-items-center mb-0 px-0">
                    {monitoringSipData.callType === "consultationInternal" ?
                    <div className="relative">
                        <i className="pi pi-users p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative bg-green-500"
                                 style={{width: '14px', height: '14px'}}>
                                <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>: monitoringSipData.callType === "consultationExternal" ?
                    <div className="relative">
                        <i className="pi pi-users p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative bg-primary"
                                 style={{width: '14px', height: '14px'}}>
                                <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div> :
                    <div className="relative">
                        <i className="pi pi-users p-2 border-circle text-white" style={{background: 'rgb(253, 166, 16)'}}></i>
                    </div>}
                    <div className="ml-2 font-bold">
                        {monitoringSipData?.direction}
                    </div>
                </div>
                <div className="grid col-12 md:col-5 mb-0 align-items-center justify-content-end px-0 pt-4 pb-3">
                    {/*<Badge value={foundStatus?.[0]?.name || 'Активний'}*/}
                    {/*       className={`${statusClass || 'status-active'} text-white customer-badge text-xs p-0`}*/}
                    {/*       style={{borderRadius: '6px'}}/>*/}
                </div>
                <div className="field col-12 md:col-12 p-0"
                     style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                <React.Fragment>
                    <div className="field col-12 md:col-5 flex align-items-center m-0">
                        <p>Клiент</p>
                    </div>
                    <div className="field col-12 md:col-7 flex align-items-center m-0" style={{wordBreak: 'break-word'}}>
                        <p>{monitoringSipData.aNI || monitoringSipData.ani || '- - -'}</p>
                    </div>

                    <div className="field col-12 md:col-5 flex align-items-center m-0">
                        <p>Власник дзвінка</p>
                    </div>
                    <div className="field col-12 md:col-7 flex align-items-center m-0" style={{wordBreak: 'break-word'}}>
                        <p>{monitoringSipData.ownerUserName || '- - -'}</p>
                    </div>
                    <div className="field col-12 md:col-5 flex align-items-center m-0">
                        <p>Тип власника</p>
                    </div>
                    <div className="field col-12 md:col-5 flex align-items-center m-0">
                        {foundOwnerType?.[0]?.description || '- - -'}
                    </div>
                    {fields.map((field: any) => (
                        <React.Fragment key={field.label}>
                            <div className="field col-12 md:col-5 flex align-items-center m-0">
                                <p>{field.label}</p>
                            </div>
                            <div className="field col-12 md:col-7 flex align-items-center m-0">
                                <p>{field.value}</p>
                            </div>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            </div>
        </div>
    );
};

export default ConsultationSipDetails;
