export const MONITORING_TABLE_TOGGLE = 'MONITORING_TABLE_TOGGLE'
export const MONITORING_DIALOG_VISIBLE = 'MONITORING_DIALOG_VISIBLE'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const SELECTED_MONITORING_ID = 'SELECTED_MONITORING_ID'
export const MONITORING_FIELD = 'MONITORING_FIELD'
export const DELETE_MONITORING = 'DELETE_MONITORING'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SHOW_ERROR = 'SHOW_ERROR'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE'
export const HIDE_ERROR = 'HIDE_ERROR'
export const SHOW_MONITORING_CREATE_MESSAGE = 'SHOW_MONITORING_CREATE_MESSAGE'
export const SHOW_MONITORING_SAVE_MESSAGE = 'SHOW_MONITORING_SAVE_MESSAGE'
export const HIDE_MONITORING_CREATE_MESSAGE = 'HIDE_MONITORING_CREATE_MESSAGE'
export const HIDE_MONITORING_SAVE_MESSAGE = 'HIDE_MONITORING_SAVE_MESSAGE'
export const INITIAL_MONITORING_DATA = 'INITIAL_MONITORING_DATA'
export const MONITORING_STATE_SAVE = 'MONITORING_STATE_SAVE'

export const GET_MONITORINGS = 'GET_MONITORINGS'
export const GET_MONITORING = 'GET_MONITORING'
export const GET_MONITORING_FOR_SIP = 'GET_MONITORING_FOR_SIP';
export const CLEAR_MONITORING_FOR_SIP = 'CLEAR_MONITORING_FOR_SIP'
export const SHOW_ERROR_FIELD = 'SHOW_ERROR_FIELD'
export const SHOW_UPDATED_ERROR = 'SHOW_UPDATED_ERROR'
export const SET_ERROR_FIELDS = 'SET_ERROR_FIELDS';
export const GET_MONITORING_PAGINATION = 'GET_MONITORING_PAGINATION'
export const SET_CURRENT_MONITORING_PAGE = 'SET_CURRENT_MONITORING_PAGE'
export const SET_CURRENT_MONITORING_SIZE = 'SET_CURRENT_MONITORING_SIZE'
export const SHOW_MONITORING_DELETE_MESSAGE = 'SHOW_MONITORING_DELETE_MESSAGE'
export const SET_LOADING = 'SET_LOADING'
export const CHANGED_MONITORING_DATA = 'CHANGED_MONITORING_DATA'
export const SHOW_DETAIL_LOADING = 'SHOW_DETAIL_LOADING'
export const SHOW_SIP_DETAILS_LOADING = 'SHOW_SIP_DETAILS_LOADING'
export const ADD_MONITORING_OBJECT = 'ADD_MONITORING_OBJECT'
export const GET_AUDIO_URL = 'GET_AUDIO_URL'
export const SHOW_EXPORT_LOADING = 'SHOW_EXPORT_LOADING'
export const GET_CAMPAIGN_NAME_LIST = 'GET_CAMPAIGN_NAME_LIST'
export const ADD_TO_MONITORING_LIST = 'ADD_TO_MONITORING_LIST';
export const MONITORING_RELOAD_TOGGLE = 'MONITORING_RELOAD_TOGGLE';
export const GET_USER_SORT_FIELDS = 'GET_USER_SORT_FIELDS';
export const UPDATE_SORT_FIELDS = 'UPDATE_SORT_FIELDS';
export const SET_FIRST_PAGE = 'SET_FIRST_PAGE';
export const GET_STATUS_DICTIONARY = 'GET_STATUS_DICTIONARY';
export const GET_CALL_RESET_DICTIONARY = 'GET_CALL_RESET_DICTIONARY';
export const GET_FORCED_CLOSING_DICTIONARY = 'GET_FORCED_CLOSING_DICTIONARY';
export const SET_MONITORING_FILTER = 'SET_MONITORING_FILTER';
export const GET_OWNER_TYPE_DICTIONARY = 'GET_OWNER_TYPE_DICTIONARY';
export const GET_CONSULTATION_FOR_SIP = 'GET_CONSULTATION_FOR_SIP'
export const CLEAR_CONSULTATION_FOR_SIP = 'CLEAR_CONSULTATION_FOR_SIP';
export const GET_DIRECTION_DICTIONARY = 'GET_DIRECTION_DICTIONARY';
export const GET_CALL_TYPE_DICTIONARY = 'GET_CALL_TYPE_DICTIONARY';
